<template>
    <div>
        <CustomDataTable :table-title="tableTitle" :endpoint="endpoint" :filters="filters" :filters-storage-key="filtersStorageKeyPrefix + filtersStorageKey" v-bind:reset-local-storage-filters-handler="resetLocalStorageFiltersHandler" :table-fields="tableFields" :search-filters="searchFilters" :buttons="buttons" :items-func="navigateToEA" ref="table" v-if="endpoint"></CustomDataTable>

        <v-dialog v-model="renewal.viewModal" width="500">
            <validation-observer ref="observerStatusChange" v-slot="{ invalid }">
                <v-card>
                    <v-card-title class="grey lighten-2">
                        Renewal Application
                    </v-card-title>

                    <v-card-text class="pt-2">
                        <v-row>
                            <v-col cols="12" sm="5" class="pt-5">
                                <div>Current Validity Till:<br>{{ $globalHelpers.getFormattedDate(renewal.validTillDate, '', 'DD/MM/YYYY') }}</div>
                                <v-row class="mt-3">
                                    <v-col cols="12" sm="6">Total Years:</v-col>
                                    <v-col cols="12" sm="6"><span class="font-size-18">{{ renewal.renewalYears }}</span></v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="7" v-if="renewal.type">
                                <v-text-field v-model="renewal.lateYears" label="Late Year(s)" hint="Late years for Renewal" v-if="renewal.type === 1" disabled></v-text-field>
                                <v-select v-model="renewal.advanceYears" :items="numberOfYears" @change="prepareRenewalYears" label="Advance Renewal Year(s)" hint="Number of advance years for Renewal" :disabled="loading"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6">

                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn type="button" class="success" :disabled="invalid || loading || renewal.renewalYears < 1" @click="createEA($globalEnums.ApplicationTypes.Renewal)">Continue</v-btn>
                    </v-card-actions>
                </v-card>
            </validation-observer>
        </v-dialog>
    </div>
</template>

<script>
    //import Vue from 'vue';
    import CustomDataTable from "../../../components/table/CustomDataTable";

    import { ValidationObserver } from 'vee-validate';
    export default {
        name: "EAs_view",
        //components: {DataRowModal, CustomDataTable},
        components: {
            CustomDataTable,
            //ValidationProvider,
            ValidationObserver,},
        data: ()=>({
            tableTitle: 'Exporter Applications',
            endpoint: null,
            routeParams: null,
            filters:{},
            filtersStorageKeyPrefix: null,
            filtersStorageKey: null,
            localStorageFilters: null,
            tableFields: [],
            searchFilters: [],

            buttons:[],
            renewal:{
                type: 0,
                viewModal: false,
                validTillDate: null,
                renewalYears: 0,
                lateYears: 0,
                advanceYears: 0,
            },

            //userId: null,
        }),
        computed:{
            numberOfYears: function () {
                let years = [];
                for(let i = 0; i < 5; i++){
                    if( i + this.renewal.lateYears <= 5){
                        years.push(i);
                    }
                }
                return years;
            }
        },
        methods:{
            loadLocalStorageFilters: async function (key) {
                let localStorageFilters = await this.$globalHelpers.getLocalStorageItem(this.filtersStorageKeyPrefix + key, null);
                if (localStorageFilters) {
                    this.localStorageFilters = JSON.parse(localStorageFilters);
                }
            },
            resetLocalStorageFiltersHandler: function (){
                this.initialize(true);
            },
            initialize: async function (loadList) {
                console.log(this.routeParams);
                this.filters = {
                    ApplicationType: null,
                    //ExporterType: null,
                    StatusIds: null,
                    StatusIdExcludes: [],
                    ServiceOfficeIds: [],
                    ServiceOfficeIdsExcludes: [],
                    ExporterTypeIds: [],
                    ExporterTypeIdsExcludes: [],
                    ApplicationTypeIds: [],
                    ApplicationTypeIdsExcludes: [],
                    UserId: null,
                    Id: null,
                    OrganizationName: null,
                    Username: null,
                    Skip: 0,
                    Take: 10,
                };
                this.filtersStorageKey = null;
                this.localStorageFilters = null;
                this.tableFields = [];
                this.searchFilters = [];

                this.renewal = {
                    type: 0,
                    viewModal: false,
                    validTillDate: null,
                    renewalYears: 0,
                    lateYears: 0,
                    advanceYears: 0,
                };

                this.filtersStorageKeyPrefix = this.$globalHelpers.getAuthStoreValue('user_id');
                await this.prepareFilters();
                this.prepareFields();
                this.prepareSearchFilters();

                if(this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.Exporter) {
                    this.filters.UserId = this.$globalHelpers.getAuthStoreValue('user_id');
                }

                if (loadList) {
                    await this.$refs.table.loadList();
                }else{
                    this.endpoint = this.$globalSettings.api.endpoints.ea.list;
                }

                await this.checkEAPermission();
            },
            prepareFilters: async function () {
                let params = this.routeParams;
                this.tableTitle = 'Exporter ';
                if (params.type === 'reg') {
                    this.filters.ApplicationType = this.$globalEnums.ApplicationTypes.Registration;
                    this.tableTitle += ' Registration';
                    this.filtersStorageKey = 'easReg';
                } else if (params.type === 'ren') {
                    this.filters.ApplicationType = this.$globalEnums.ApplicationTypes.Renewal;
                    this.tableTitle += ' Renewal';
                    this.filtersStorageKey = 'easRen';
                } else if (params.type === 'ac') {
                    this.filters.ApplicationType = this.$globalEnums.ApplicationTypes.Address_Change;
                    this.tableTitle += ' Information Change';
                    this.filtersStorageKey = 'easAc';
                } else {
                    this.filters.ApplicationType = null;
                }

                this.filters.ExporterType = this.$globalHelpers.getAuthStoreValue('user_exporter_type');

                this.filters.StatusIds = [];
                if (params.status === 'pending') {
                    await this.loadLocalStorageFilters(this.filtersStorageKey);
                    console.log(this.filtersStorageKey);
                    console.log(this.localStorageFilters);
                    if (this.localStorageFilters) {
                        this.filters.StatusIds = this.localStorageFilters.StatusIds;
                        this.filters.StatusIdExcludes = this.localStorageFilters.StatusIdExcludes;
                    } else {
                        this.filters.StatusIds.push(this.$globalEnums.ApplicationStatuses.Inspection);
                        if (this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.DG) {
                            this.filters.StatusIds.push(this.$globalEnums.ApplicationStatuses.DG_desk);
                            this.filters.StatusIds.push(this.$globalEnums.ApplicationStatuses.Team_Approval);
                        } else if (this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.Director) {
                            this.filters.StatusIds.push(this.$globalEnums.ApplicationStatuses.Report_Submitted);
                            this.filters.StatusIds.push(this.$globalEnums.ApplicationStatuses.Director_desk);
                        } else if (this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.DD) {
                            this.filters.StatusIds.push(this.$globalEnums.ApplicationStatuses.DD_Desk);
                        } else if (this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.AD) {
                            this.filters.StatusIds.push(this.$globalEnums.ApplicationStatuses.AD_desk);
                        } else if (this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.Enrollment_Executive) {
                            this.filters.StatusIds.push(this.$globalEnums.ApplicationStatuses.Enrollment_Executive_Desk);
                            console.log(this.filters.StatusIds);
                        } else if (this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.DA) {
                            this.filters.StatusIds.push(this.$globalEnums.ApplicationStatuses.DA_desk);
                        } else {
                            this.filters.StatusIds = [
                                this.$globalEnums.ApplicationStatuses.New,
                                this.$globalEnums.ApplicationStatuses.DA_desk,
                                this.$globalEnums.ApplicationStatuses.Enrollment_Executive_Desk,
                                this.$globalEnums.ApplicationStatuses.AD_desk,
                                this.$globalEnums.ApplicationStatuses.DD_Desk,
                                this.$globalEnums.ApplicationStatuses.Director_desk,
                                this.$globalEnums.ApplicationStatuses.Team_Approval,
                                this.$globalEnums.ApplicationStatuses.Inspection,
                                this.$globalEnums.ApplicationStatuses.Report_Submitted,
                                this.$globalEnums.ApplicationStatuses.DG_desk,
                                this.$globalEnums.ApplicationStatuses.Rejected,
                            ];
                        }
                        this.filters.StatusIdExcludes = [
                            this.$globalEnums.ApplicationStatuses.Approved,
                            this.$globalEnums.ApplicationStatuses.Cancelled
                        ];
                    }
                    console.log(this.filters.StatusIds);

                    this.tableTitle += ' Pending';
                } else if (params.status === 'inspection') {
                    this.filtersStorageKey = 'easInsp';
                    await this.loadLocalStorageFilters(this.filtersStorageKey);
                    if (this.localStorageFilters) {
                        this.filters.StatusIds = this.localStorageFilters.StatusIds;
                        this.filters.StatusIdExcludes = this.localStorageFilters.StatusIdExcludes;

                        this.filters.ApplicationTypeIds = this.localStorageFilters.ApplicationTypeIds;
                    } else {
                        this.filters.StatusIds = [
                            this.$globalEnums.ApplicationStatuses.Inspection,
                            this.$globalEnums.ApplicationStatuses.Team_Approval,
                            this.$globalEnums.ApplicationStatuses.Report_Submitted,
                        ];
                        this.filters.StatusIdExcludes = [
                            this.$globalEnums.ApplicationStatuses.New,
                            this.$globalEnums.ApplicationStatuses.DA_desk,
                            this.$globalEnums.ApplicationStatuses.Enrollment_Executive_Desk,
                            this.$globalEnums.ApplicationStatuses.AD_desk,
                            this.$globalEnums.ApplicationStatuses.DD_Desk,
                            this.$globalEnums.ApplicationStatuses.Director_desk,
                            this.$globalEnums.ApplicationStatuses.DG_desk,
                            this.$globalEnums.ApplicationStatuses.Approved,
                            this.$globalEnums.ApplicationStatuses.Rejected,
                            this.$globalEnums.ApplicationStatuses.Cancelled,
                        ];
                    }

                    this.tableTitle += ' Inspections';
                } else if (params.status === 'completed') {
                    this.filtersStorageKey = 'easComp';
                    await this.loadLocalStorageFilters(this.filtersStorageKey);
                    if (this.localStorageFilters) {
                        this.filters.StatusIds = this.localStorageFilters.StatusIds;
                        this.filters.StatusIdExcludes = this.localStorageFilters.StatusIdExcludes;

                        this.filters.ApplicationTypeIds = this.localStorageFilters.ApplicationTypeIds;
                    } else {
                        this.filters.StatusIds = [
                            this.$globalEnums.ApplicationStatuses.Approved,
                            this.$globalEnums.ApplicationStatuses.Cancelled,
                        ];
                        this.filters.StatusIdExcludes = [
                            this.$globalEnums.ApplicationStatuses.New,
                            this.$globalEnums.ApplicationStatuses.DA_desk,
                            this.$globalEnums.ApplicationStatuses.Enrollment_Executive_Desk,
                            this.$globalEnums.ApplicationStatuses.AD_desk,
                            this.$globalEnums.ApplicationStatuses.DD_Desk,
                            this.$globalEnums.ApplicationStatuses.Director_desk,
                            this.$globalEnums.ApplicationStatuses.Team_Approval,
                            this.$globalEnums.ApplicationStatuses.DG_desk,
                            this.$globalEnums.ApplicationStatuses.Inspection,
                            this.$globalEnums.ApplicationStatuses.Report_Submitted,
                            this.$globalEnums.ApplicationStatuses.Rejected,
                        ];
                    }

                    this.tableTitle += ' Completed';
                }

                if (params.status !== 'inspection') {
                    this.tableTitle += ' Applications';
                }

                if (this.localStorageFilters) {
                    this.filters.ServiceOfficeIds = this.localStorageFilters.ServiceOfficeIds;

                    if (this.localStorageFilters.ExporterTypeIds && this.$globalHelpers.getAuthStoreValue('user_role_id') !== this.$globalEnums.roles.Exporter) {
                        if(this.localStorageFilters.ExporterTypeIds.length > 0) {
                            this.filters.ExporterTypeIds = this.localStorageFilters.ExporterTypeIds;
                        }else {
                            this.filters.ExporterTypeIds = [
                                this.$globalEnums.ExporterTypes.None,
                                this.$globalEnums.ExporterTypes.Textile,
                                this.$globalEnums.ExporterTypes.NonTextile,
                            ];
                        }
                    }else{
                        this.filters.ExporterTypeIds.push(this.$globalHelpers.getAuthStoreValue('user_exporter_type'));
                    }

                    //this.filters.Id = this.localStorageFilters.Id;
                    //this.filters.OrganizationName = this.localStorageFilters.OrganizationName;
                    //this.filters.Username = this.localStorageFilters.Username;
                } else {
                    if (this.$globalHelpers.getAuthStoreValue('user_role_id') === this.$globalEnums.roles.Admin) {
                        this.filters.ServiceOfficeIds = [
                            this.$globalEnums.ServiceOffices.None,
                            this.$globalEnums.ServiceOffices.Dhaka,
                            this.$globalEnums.ServiceOffices.Narayanganj,
                            this.$globalEnums.ServiceOffices.Chattogram,
                            this.$globalEnums.ServiceOffices.Cumilla,
                            this.$globalEnums.ServiceOffices.Khulna,
                            this.$globalEnums.ServiceOffices.Rajshahi,
                            this.$globalEnums.ServiceOffices.Sylhet,
                        ];
                    } else {
                        this.filters.ServiceOfficeIds.push(this.$globalHelpers.getAuthStoreValue('user_service_office_id'));
                    }

                    if(this.$globalHelpers.getAuthStoreValue('user_role_id') !== this.$globalEnums.roles.Exporter){
                        this.filters.ExporterTypeIds = [
                            this.$globalEnums.ExporterTypes.None,
                            this.$globalEnums.ExporterTypes.Textile,
                            this.$globalEnums.ExporterTypes.NonTextile,
                        ];
                    }else{
                        this.filters.ExporterTypeIds.push(this.$globalHelpers.getAuthStoreValue('user_exporter_type'));
                    }
                }
            },
            prepareFields: function(){
                this.tableFields = [
                    {key: 'Id', label: 'Application ID', type: 'number', orderBy: true},
                    {key: 'User.ExporterType', label: 'Dept.', type: 'array', array: this.$globalHelpers.enumToArray(this.$globalEnums.ExporterTypes), excludes: [], orderBy: false},
                    {key: 'ServiceOfficeId', label: 'Serv. Office', type: 'arrayFilter', filterKey: 'ServiceOfficeIds', array: this.$globalHelpers.enumToArray(this.$globalEnums.ServiceOffices), excludes: this.filters.ServiceOfficeIds, orderBy: true},
                    {key: 'User.OrganizationName', label: 'Organization', type: 'string', orderBy: false},
                    {key: 'User.Username', label: 'Reg. No.', type: 'string', orderBy: false},
                    {key: 'ApplicationType', label: 'Type', type: 'array', array: this.$globalHelpers.enumToArray(this.$globalEnums.ApplicationTypes), excludes: [] },
                    {key: 'StatusId', label: 'Status', type: 'arrayFilter', filterKey: 'StatusIds', array: this.$globalHelpers.enumToArray(this.$globalEnums.ApplicationStatuses), excludes: this.filters.StatusIdExcludes, orderBy: true},
                    {key: 'OfficeAddress', label: 'Offc. Addr.', type: 'string', enumObj: null},
                    {key: 'OfficePhone', label: 'Offc. Phone', type: 'string', enumObj: null},
                    //{key: 'OfficeEmail', label: 'Offc. Email', type: 'string', enumObj: null},
                    {key: 'Modified', label: 'Updated', type: 'date', orderBy: true},
                ];
            },
            prepareSearchFilters: function(){
                this.searchFilters = [
                    {key: 'Id', label: 'Application ID', type: 'number'},
                    {key: 'OrganizationName', label: 'Organization', type: 'string'},
                    {key: 'Username', label: 'Reg. No.', type: 'string'},
                    {key: 'StatusId', label: 'Status', type: 'arrayFilter', filterKey: 'StatusIds', array: this.$globalHelpers.enumToArray(this.$globalEnums.ApplicationStatuses), excludes: this.filters.StatusIdExcludes},
                    {key: 'ServiceOfficeId', label: 'Service Office', type: 'arrayFilter', filterKey: 'ServiceOfficeIds', array: this.$globalHelpers.enumToArray(this.$globalEnums.ServiceOffices), excludes: []},
                ];
                if(this.$globalHelpers.getAuthStoreValue('user_role_id') !== this.$globalEnums.roles.Exporter){
                    this.searchFilters.push({key: 'ExporterTypeId', label: 'Department', type: 'arrayFilter', filterKey: 'ExporterTypeIds', array: [{ id: this.$globalEnums.ExporterTypes.None, label: 'None' }, { id: this.$globalEnums.ExporterTypes.Textile, label: 'Textile' }, { id: this.$globalEnums.ExporterTypes.NonTextile, label: 'Non-Textile' } ], excludes: []});
                }
                if (this.routeParams.type === 'all' && (this.routeParams.status === 'completed' || this.routeParams.status === 'inspection')) {
                    this.searchFilters.push({key: 'ApplicationTypeId', label: 'Application Type', type: 'arrayFilter', filterKey: 'ApplicationTypeIds', array: [{ id: this.$globalEnums.ApplicationTypes.Registration, label: 'Registration' }, { id: this.$globalEnums.ApplicationTypes.Renewal, label: 'Renewal' }, { id: this.$globalEnums.ApplicationTypes.Address_Change, label: 'Address_Change' } ], excludes: []});
                }
            },
            checkEAPermission: async function(){
                this.loading = true;
                this.buttons = [];
                this.renewal.viewModal = false;
                this.renewal.type = 0;
                this.renewal.validTillDate = null;
                await this.$axios.get(this.$globalSettings.api.endpoints.ea.permissions, { params: { userId: this.$globalHelpers.getAuthStoreValue('user_id') }}).then(async (response) => {
                    this.loading = false;
                    if(response.data.ShowReg == 1 && this.$route.params.type === 'reg'){
                        this.buttons.push({ label: 'Apply for Registration', icon: 'fas fa-id-card', type: 'nav', func: this.createEA, funcParam: this.$globalEnums.ApplicationTypes.Registration },);
                    }else if(this.$route.params.type === 'ren' && response.data.ShowRen){
                        this.renewal.validTillDate = response.data.ValidTillDate;
                        this.renewal.type = response.data.RenewalType;
                        this.renewal.lateYears = response.data.RenDueYears;
                        this.prepareRenewalYears();

                        this.buttons.push({ label: 'Apply for Renewal', icon: 'fas fa-repeat', type: 'nav', func: this.eaCustomRenDialog, funcParam: this.$globalEnums.ApplicationTypes.Renewal },);
                    }else if(response.data.ShowAC == 1 && this.$route.params.type === 'ac'){
                        this.buttons.push({ label: 'Apply for Address Change', icon: 'fas fa-map-location-dot', type: 'nav', func: this.createEA, funcParam: this.$globalEnums.ApplicationTypes.Address_Change },);
                    }else{
                        this.buttons = [];
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            },
            prepareRenewalYears: function (){
                if(this.renewal.type === 2){
                    this.renewal.lateYears = 0;
                }

                this.renewal.renewalYears = this.renewal.lateYears + this.renewal.advanceYears;
            },

            eaCustomRenDialog: async function () {
                console.log('eaCustomRenDialog');
                this.renewal.viewModal = true;
            },
            createEA: async function (type) {
                this.loading = true;
                let formData = {
                    ApplicationType: type,
                    StatusId: this.$globalEnums.ApplicationStatuses.New,
                    UserId: this.filters.UserId,
                    RenewalType: this.renewal.type,
                    PaymentYears: this.renewal.renewalYears,
                    LatePaymentYears: this.renewal.lateYears,
                    AdvancePaymentYears: this.renewal.advanceYears,
                };
                await this.$axios.post(this.$globalSettings.api.endpoints.ea.save, formData).then(async (response) => {
                    this.loading = false;
                    this.$toast.open({ message: response.data.Msg,  type: response.data.Status});
                    if(response.data.Status == 'success'){
                        await this.$refs.table.loadList();
                        await this.checkEAPermission();
                    }
                }).catch((error) => {
                    this.loading = false;
                    this.$globalHelpers.processHttpErrorsToast(error);
                });
            },
            navigateToEA: async function (id) {
                await this.$router.push({name: 'App.EA', params: {id: id}});
            },
        },
        created() {
            this.endpoint = null;
            this.routeParams = this.$route.params;
            this.initialize(false);

            this.$watch(
                () => this.$route.params,
                // eslint-disable-next-line no-unused-vars
                (toParams, previousParams) => {
                    this.routeParams = toParams;
                    this.initialize(true);
                }
            );
        }
    }
</script>

<style scoped>

</style>